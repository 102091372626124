@import "config";

.Gallery{
    min-height: 758px;
    padding-top: $nav_bar;
    img{
        width: 300px;
    }

}
