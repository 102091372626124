@import "config";

@keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }

#Checkout{
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  img{
    max-width: 100vw;

 
  } 
  #award1{
    display: none;
    @include for_breakpoint(desktop){
      display: block;
    }
  }

  #award2{
    display: block;
    padding-top: 114px;
    @include for_breakpoint(desktop){
      display: none;
    }
  }

  
  #donate-wrapper{
    min-height: 250px;
    padding: 5%;
    display: flex;
    flex-wrap: wrap;
    #copy1{
      flex: 1 0;
    }
      #button-holder{
          @include for_breakpoint(desktop){
            max-width: 45%;
          }
      #checkout-button{
        cursor: POINTER;
        float: center;
        margin: 1em;
        min-width: 20%;
      }
    }
  }
}
