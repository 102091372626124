@import "config";

.Events{
    min-height: 758px;
    padding-top: $nav_bar;
    width: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    #Poster{
           @include for_breakpoint(desktop){
      width: 70vw;
      }
        
      width: 100%;
      padding: 0 0 2em 0;
    }

}
