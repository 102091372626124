@import "config";


    #mobile-hero{
        max-width: 100%;
        height: 100%;
    }
    #desktop-hero{
        display: none;
    }
    @include for_breakpoint(desktop){
        #mobile-hero{
            display: none;
            
        }
        #desktop-hero{
                max-width: 100%;
                position: relative;
                display:block;
                }
    }
