
@import "config";

#home{
display: flex;
padding-top: $nav_bar;
flex-wrap: wrap;

    #email-capture{
        background-color: #75C366;
        min-height: 84px;
        width: 100%;
        -webkit-box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.548);
        -moz-box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.548);
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.548);

    }
    #gallery{
        position: relative;
        background-color: #EBEBEB;
        min-height: 0px;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        padding: 4em 0;
    }
}

