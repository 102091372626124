
@import "config";

html {
margin: 0;
padding: 0;
border: 0;
scroll-behavior: smooth;
height: 100%;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.target { 
  mask: url(#mask-1); 
}
p {
  display: inline-block;
}

.bx{
  position: relative;
  display: flex;
  flex-flow: wrap;
  padding: 0;
  border: 0;
  margin: 0;
  width: 100%;
  height: auto;  


}