.footer{  flex-shrink: 0;
    position: relative;
    min-height: 150px;
    width: 100%;
    background-color: #75C366;
    display: grid;
    grid-template-areas: 
        "s a . m . e"
        "c c c c c c";
    gap: 0;
    font-size: 1em;
    h4{
        margin-bottom: 0;
    }

    #contact-us{
        grid-area: a;
        text-align: left;
        padding-left: 1em;
    }
    #social-media-links{
        grid-area: s;
        display: flex;
        flex-direction: column;
        #link-container{        
            display: flex;
            flex-direction: column;
            float: left;
            #Twitter-Logo{
                padding-right: 20px;
            }
            .social-icons{
                height: 1.4em;
                margin-right: 4px;
            }
        }
    }
    #Copyright{
        margin: 1em 0px;
        grid-area: c;
    }
    a{
        text-decoration: none;
        color: black;

        -moz-text-decoration-color: inherit;
    }
}