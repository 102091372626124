@import "config";

.gallery-box{
  -webkit-box-shadow: -9px 6px 14px -4px rgba(0,0,0,0.52);
  -moz-box-shadow: -9px 6px 14px -4px rgba(0,0,0,0.52);
  box-shadow: -9px 6px 14px -4px rgba(0,0,0,0.52);
    min-width: 70%;    
    display: flex;
    flex-direction: column;
    margin-bottom: 3em;
    @include for_breakpoint(desktop){
      flex-direction: row;
      margin: 3em;
    
    }
    @include for_breakpoint(tablet){
      #hero-gallery-image{
        width: fit-content;
      }
      height: fit-content;
    }
  #text-holder{
    color: rgba(0, 0, 0, 0.870);
    text-align: left;
    margin: 0 1em;
  
    @include for_breakpoint(desktop){
      width: 60%;
      height: 100%;
      margin: 0 2em;
    }

    .title{
        font-family: 'Poppins', sans-serif;
        margin-top: 1em;
    }
    .copy{
        font-family: 'Open Sans', sans-serif;
        line-height: 1.5rem;
  
    }
  }
}

      #gallery-item-container-1{
        @include for_breakpoint(desktop){
          margin-right: 10%;
        }
        border-radius: 10px;
        overflow: hidden;
        background-color: #B3DCA7;
      }
    
      #gallery-item-container-2{  
        @include for_breakpoint(desktop){
          margin-left: 10%;
        }
        border-radius: 10px;
        overflow: hidden;
        background-color: #B3DCA7;
      }  
    
         
      #gallery-item-container-3{
        @include for_breakpoint(desktop){
          margin-right: 10%;

        }
        border-radius: 10px;
        overflow: hidden;
        background-color: #B3DCA7;


      }  
    


  
/* ----------------------------------------------
 * Generated by Animista on 2020-9-3 10:43:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-right-1
 * ----------------------------------------
 */
 @-webkit-keyframes tilt-in-right-1 {
    0% {
      -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
              transform: rotateX(-30deg) translateX(300px) skewX(30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
              transform: rotateX(0deg) translateX(0) skewX(0deg);
      opacity: 1;
    }
  }
  @keyframes tilt-in-right-1 {
    0% {
      -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
              transform: rotateX(-30deg) translateX(300px) skewX(30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
              transform: rotateX(0deg) translateX(0) skewX(0deg);
      opacity: 1;
    }
  }
  