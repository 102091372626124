@import "config";


  
    #about-us-container{
        flex: 1 0 auto;
        display: grid;
        justify-items: center;

    @include for_breakpoint(mobile){
        margin-top: $nav_bar;
        padding-top: 0;

        #ITEL-Mobile-Model{
            background-color: #37712b;

            max-width: 100%;
        }
        #ITEL-Model{
            display: none;
        }
    }

    @include for_breakpoint(desktop){
        grid-template-rows: min-content 50vw;
        padding-top: 1em;

        #ITEL-Mobile-Model{
            display: none;
        }
        #ITEL-Model{
            display: block;
            max-width: 50vw;
            }
    }
#About-us-copy{
    margin: 1em 3em 5em;
     @include for_breakpoint(desktop){    
       max-width: 50%;
     }
}
}


