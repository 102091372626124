@import "config";

#email-capture{
    display: flex;
    flex-direction: column;
    align-items: center;

        #form{
        display: flex;
        flex-direction: column;
        width: 45%;
        
        @include for_breakpoint(desktop){
            display: block;
            label{
            padding: 1%;            }
        }
        
      button{
        background-color:rgb(255, 255, 0) ;
        border-style: hidden;
        padding: 10%;
        margin: 1em 0em 1em;

        @include for_breakpoint(desktop){
          padding: 8px 30px;
          margin: 0 2% 2%;
  
        }

      }
      }
    }
